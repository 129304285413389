import {StaticImage} from 'gatsby-plugin-image';
import React, {useEffect, useRef, useState} from 'react';
import MouseScrollIcon from './_assets/mouse-scroll.svg';
import './HeroStage.scss';

import {SplitText} from 'gsap/SplitText';
import {gsap} from 'gsap';


gsap.registerPlugin(SplitText);


const HeroStage = (props) =>
{
  const contRef = useRef(null);
  const query = gsap.utils.selector(contRef);

  useEffect(() =>
  {
//    const slitText = new SplitText(query('p'), {type: 'lines'});
//    const chars = slitText.lines;
//
//    gsap.from(query('p'), {
//      opacity: 0,
//      y      : 20
//    });

    gsap.to(query('.icon'),
      {
        y: -40, duration: .3, yoyo: true, repeat: -1,
        scrollTrigger                           :
          {
            trigger      : query('.icon'),
            end          : 'bottom center',
            toggleActions: 'play complete play none'
          }
      }
    );


  }, []);


  return (
    <>
      <div className={'heroStage'} ref={contRef}>
        <StaticImage src={'./../images/mountain.jpg'} alt={''} layout={'fullWidth'} style={{height: '100vh'}} />
        <div className={'overlay'}>
          <div className={'content'}>
            <p>Có cả một thế giới rộng lớn và kì diệu đang chờ bạn...Hãy để chúng tôi đưa bạn đi!</p>
          </div>
        </div>
        <div className={'mouse'}>
          <div className={'icon'}>
            <MouseScrollIcon />
          </div>
        </div>
      </div>
    </>
  );
};

export {HeroStage};
